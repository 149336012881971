import React from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import FooterForm from "../../components/common/FooterForm";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import CenterContentHome from "../../components/common/CenterContentHeader";
import { RequestForm } from "../../components/form";

const topImage = require("../../assets/images/10-pages/02_itsupport_individual_1/Itsupport_ind_1_header.png");
export const icon1 = require("../../assets/images/10-pages/02_itsupport_individual_1/Itsupport_ind_1_sec_1_icon.png");
export const icon2 = require("../../assets/images/10-pages/02_itsupport_individual_1/Itsupport_ind_1_sec_2_icon.png");
export const icon3 = require("../../assets/images/10-pages/02_itsupport_individual_1/Itsupport_ind_1_sec_3_icon.png");
export const icon4 = require("../../assets/images/10-pages/02_itsupport_individual_1/Itsupport_ind_1_sec_4_icon.png");

const section_one = require("../../assets/images/10-pages/02_itsupport_individual_1/Itsupport_ind_1_sec_1.png");
const section_two = require("../../assets/images/10-pages/02_itsupport_individual_1/Itsupport_ind_1_sec_2.png");
const section_three = require("../../assets/images/10-pages/02_itsupport_individual_1/Itsupport_ind_1_sec_3.png");
const section_four = require("../../assets/images/10-pages/02_itsupport_individual_1/Itsupport_ind_1_sec_4.png");

const featureContent = [
  {
    icon: icon1,
    header: "Intelligent, time-saving IT self-service.",
    content:
      "Build your AI-powered IT chatbot with zero-coding and enable a smart conversational AI self-service for employees. Our IT chatbot automatically delivers instant response and resolution.",
    link: null,
    image: section_one,
  },
  {
    icon: icon2,
    header: "Use Slack and Teams to deliver IT Support",
    content:
      "Delight your employees with our always-on, follow-me, interactive chatbot, from the comfort of their favorite apps like Slack or Teams. ",
    link: null,
    image: section_two,
    toDisplaySlackIcons: true,
  },
  {
    icon: icon3,
    header: "Anytime, anywhere 24/7",
    content:
      "With our chatbot, employees can get instant support 24/7 regardless of where they are. Scale your support team even when they are busy or offline.",
    link: null,
    image: section_three,
  },
  {
    icon: icon4,
    header: "Lightning-fast first contact resolution. ",
    content:
      "With our chatbot, you can eliminate the need to go back and forth on most IT support tickets with our automated resolution – our unique differentiator. ",
    link: null,
    image: section_four,
  },
];

const SlackAndTeams = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
  </div>
);

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Digital Transformation of IT Support with Conversational AI Chatbot and Process Automation |  Workativ Assistant"
        description="Build AI-powered IT Support Chatbot with zero coding. Enable a smart, conversational AI self-service for employees."
        keywords={[
          "Workativ  trial",
          "workativ free trial",
          "helpdesk chatbot free trial",
          "automation",
        ]}
        ogImage={topImage}
        ogTitle="Digital Transformation of IT Support with Conversational AI Chatbot and Process Automation |  Workativ Assistant"
        ogDescription="Build AI-powered IT Support Chatbot with zero coding. Enable a smart, conversational AI self-service for employees."
      />
      <Container>
        <Layout backgroundColor={"bg_header_suppport_one"} logoFor="ASSISTANT">
          <RegisterSection
            rightImage={topImage}
            backgroundColor={"bg_header_suppport_one"}
          >
            <RegisterSection.FormWithContent>
              <RegisterSection.MainHeader>
                Digital transformation of IT support
              </RegisterSection.MainHeader>
              <RegisterSection.SubHeader>
                Eliminate wait time for your employees by offering instant IT
                support anytime, anywhere, with an advanced chatbot.
              </RegisterSection.SubHeader>
            </RegisterSection.FormWithContent>
          </RegisterSection>{" "}
          <CenterContentHome>
            <CenterContentHome.Header>
              What makes our employee support solution different
            </CenterContentHome.Header>
          </CenterContentHome>
          {featureContent.map((feature, index) => {
            if (index % 2 !== 0) {
              return (
                <RightImageWithContentFeature
                  image={feature.image}
                  altImage={feature.header}
                >
                  <RightImageWithContentFeature.Header>
                    <div className="icon-top-img">
                      <img src={feature.icon} alt="icon" />{" "}
                    </div>
                    <h3>{feature.header}</h3>
                  </RightImageWithContentFeature.Header>
                  <RightImageWithContentFeature.SubHeader>
                    <p>{feature.content}</p>
                  </RightImageWithContentFeature.SubHeader>
                  <RightImageWithContentFeature.Link>
                    {feature.link && (
                      <div className="card_link_landing">
                        <a href={feature.url} className="url_manipulation">
                          {feature.link}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    )}
                    {feature.toDisplaySlackIcons != undefined && (
                      <SlackAndTeams />
                    )}
                  </RightImageWithContentFeature.Link>
                </RightImageWithContentFeature>
              );
            }
            return (
              <LeftImageWithContent
                image={feature.image}
                altImage={feature.header}
              >
                <LeftImageWithContent.HeaderIcon>
                  <div className="icon-top-img">
                    <img src={feature.icon} alt="icon" />{" "}
                  </div>
                </LeftImageWithContent.HeaderIcon>

                <LeftImageWithContent.MainHeader>
                  {feature.header}
                </LeftImageWithContent.MainHeader>
                <LeftImageWithContent.SubHeader>
                  <p>{feature.content}</p>
                </LeftImageWithContent.SubHeader>
                <LeftImageWithContent.Link>
                  <div className="card_link_landing">
                    {feature.link && (
                      <a href={feature.link} className="url_manipulation">
                        Learn more{" "}
                        <span className="arrow_svg_link">
                          <ArrowRightICon />
                        </span>
                      </a>
                    )}
                    {feature.toDisplaySlackIcons != undefined && (
                      <SlackAndTeams />
                    )}
                  </div>
                </LeftImageWithContent.Link>
              </LeftImageWithContent>
            );
          })}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
